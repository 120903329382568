import Vue from 'vue'
import { extend } from "vee-validate"
import { required , length , min} from "vee-validate/dist/rules"


extend('required',{
    ...required,
    message:"Campo Obrigatório"
})

extend('length',{
    ...length,
    message:'Somente {length} caracteres'
})

extend('min',{
    ...length,
    message:"Minimo de {min} caracteres"
})

extend('password',{
    params:['target'],
    validate(value,{ target}){
        return value === target;
    },
    message: "Senhas não conferem"
})