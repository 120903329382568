import Vue from "vue";

Vue.filter("number_ptbr_ficha", function (value) {
  if (!value) return "";
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 0,
  });
});

Vue.filter("number_ptbr", function (value) {
  if (!value) return 0;
  return value.toLocaleString("pt-BR", { maximumFractionDigits: 2 });
});

Vue.filter("number_ptbr_2_decimals", function (value) {
  if (!value) return 0;
  return value.toLocaleString("pt-BR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
});

Vue.filter("contabil_money", function (value) {
  if (!value) return 0;
  let style = {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 0,
  };
  let valor =
    value < 0
      ? `(${Math.abs(value).toLocaleString("pt-BR", style)})`
      : value.toLocaleString("pt-BR", style);
  return valor;
});

Vue.filter("contabil", function (value) {
  if (!value) return 0;
  let valor =
    value < 0
      ? `(${Math.abs(value).toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
        })})`
      : value.toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
        });
  return valor;
});

Vue.filter("number_inteiro", function (value) {
  if (!value) return 0;
  let valor = parseInt(value);
  return valor;
});
