import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import storeDashBoard from "./modules/storeDashBoard";
import defaultState from "./states/defaultState";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    storeDashBoard,
  },
  state: {
    authenticated: false,
    sidebar: false,
    user: {},
    spinner: false,
    programacao_imported: [],
    drawer: false,
    latadas_invalidas: [],
    latadas_duplicadas: [],
    samePeriod: [],
    culturas: [
      {
        text: "UVA",
        value: "UVA",
      },
      {
        text: "MANGA",
        value: "MANGA",
      },
    ],
    filiais: [
      {
        name: "VALE DO SOL",
        id: 2,
      },
      {
        name: "SERENISSIMA",
        id: 3,
      },
    ],
    isLoading: false,
    anos: [
      {
        valor: 2021,
        text: "2021",
      },
      {
        valor: 2022,
        text: "2022",
      },
      {
        valor: 2023,
        text: "2023",
      },
      {
        valor:2024,
        text:2024
      }
    ],
    months: [
      {
        valor: 1,
        nome: "Janeiro",
      },
      {
        valor: 2,
        nome: "Fevereiro",
      },
      {
        valor: 3,
        nome: "Março",
      },
      {
        valor: 4,
        nome: "Abril",
      },
      {
        valor: 5,
        nome: "Maio",
      },
      {
        valor: 6,
        nome: "Junho",
      },
      {
        valor: 7,
        nome: "Julho",
      },
      {
        valor: 8,
        nome: "Agosto",
      },
      {
        valor: 9,
        nome: "Setembro",
      },
      {
        valor: 10,
        nome: "Outubro",
      },
      {
        valor: 11,
        nome: "Novembro",
      },
      {
        valor: 12,
        nome: "Dezembro",
      },
    ],
    ciclos: [
      {
        valor: "Produção",
        nome: "Produção",
      },
      {
        valor: "Formação",
        nome: "Formação",
      },
    ],
  },
  plugins: [
    createPersistedState({
      paths: ["user", "authenticated"],
    }),
  ],
  getters: {
    totalLatadasInvalidas(state) {
      return state.latadas_invalidas.length > 0;
    },
    totalSamePeriod(state) {
      return state.samePeriod.length > 0;
    },
    programacaoFiltered(state) {
      return state.programacao_imported;
    },
    totalLatadasDuplicadas(state) {
      return state.latadas_duplicadas.length > 0;
    },

    getFiliais(state){
      return state.filiais
    }
  },
  mutations: {
    setLoader(state, payload) {
      state.isLoading = payload;
    },
    setFiliais(state, payload) {
      state.filiais = payload;
    },
    setDrawer(state, payload) {
      state.drawer = payload;
    },
    login(state, payload) {
      state.sidebar = true;
      state.authenticated = true;
      state.user = payload;
    },

    SET_LOGOUT(state) {
      Object.assign(state, defaultState());
      window.location.href = "#/login";
    },

    handleSpinner(state, payload) {
      state.spinner = payload;
    },
    updateProgramacaoData(state, payload) {
      state.programacao_imported = payload;
    },
    setLatadasInvalidas(state, payload) {
      state.latadas_invalidas = payload;
    },
    setLatadasDuplicadas(state, payload) {
      state.latadas_duplicadas = payload;
    },
    setSamePeriod(state, payload) {
      state.samePeriod = payload;
    },
    updateApontamentoData(state, payload) {
      state.programacao_apontamento = payload;
    },
  },
  actions: {
    showLoader({ commit }) {
      commit("setLoader", true);
    },
    hideLoader({ commit }) {
      commit("setLoader", false);
    },
    populateFiliais(context) {
      context.commit("setFiliais", [
        {
          id: 1,
          nome_fantasia: "TODOS",
        },
        {
          id: 3,
          nome_fantasia: "SERENISSIMA",
        },
        {
          id: 2,
          nome_fantasia: "VALE DO SOL",
        },
      ]);
    },

    clearApontamento(context) {
      context.commit("updateApontamentoData", []);
    },
    clearProgramacao(context) {
      context.commit("updateProgramacaoData", []);
      context.commit("setLatadasInvalidas", []);
      context.commit("setLatadasDuplicadas", []);
    },
    loginAction(context, payload) {
      return new Promise((resolve) => {
        context.commit("login", payload);
        resolve();
      });
    },
    logoutAction(context) {
      return new Promise((resolve) => {
        context.commit("logout");
        resolve();
      });
    },
  },
});

export default store;
