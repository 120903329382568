const LayoutUserActionsMixin = {
  data() {
    return {
      toastOption: {
        position: "top",
      },
      optionsVueMoney: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
    };
  },
  methods: {
    teste() {
      return "abc";
    },
    openToast(type, message, position) {
      let options = {
        position,
        timeout: 3000,
        icon: "fas fa-rocket",
      };
      switch (type) {
        case "success":
          this.$toast.success(message, options);
          break;
        case "warning":
          this.$toast.warning(message, options);
          break;
        case "error":
          this.$toast.error(message, options);
          break;
        default:
          break;
      }
    },
  },

  computed: {},
};

export default LayoutUserActionsMixin;
