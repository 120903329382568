import Vue from "vue";
import moment from 'moment'
const MiscPlugin = {
  install(Vue, options) {
    Vue.prototype.$formatNumber = (number) => {
      return number.toLocaleString("pt-BR");
    };

    Vue.prototype.$formatDate = (date) => {
      return moment(date).format('DD/MM/YYYY')
    }
   
  },
};

export default MiscPlugin;
