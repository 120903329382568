import Vue from "vue";

import "./plugins/axios";
import "./plugins/hightcharts";
import App from "./App.vue";

import axios from "axios";
import "./plugins/vee-rules";
import "./plugins/validation";
import "./plugins/currency";
import "./plugins/loading";
import MiscPlugin from "./plugins/misc";

import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./filters/index.js";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as fileDownload from "js-file-download";
import "./plugins/vuetify-mask";
import _ from "lodash";

//import "./config/global"
import VueSweetAlert from "vue-sweetalert2";
import VueAxios from "vue-axios";
import "@fortawesome/fontawesome-free/css/all.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "dual-listbox-vue/dist/dual-listbox.css";
import LayoutUserActionsMixin from "./assets/actions/LayoutUserActions";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "$fileDownload", { value: fileDownload });

Vue.use(VueSweetAlert);
Vue.mixin(LayoutUserActionsMixin);
Vue.use(_);
Vue.use(VueAxios, axios);
Vue.use(Toast);
Vue.use(MiscPlugin);

Vue.prototype.$axios = axios;

new Vue({
  render: (h) => h(App),
  vuetify,
  router,
  store: store,
}).$mount("#app");
