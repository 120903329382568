import axios from 'axios'

const storeDashBoard =  {
    namespaced: true,
    state:{
        data:{},
        dash_detailed:[],
        pageDetailed:null,
        filtros_detailed:[],
    },
    getters:{
        hasDashDetailed(state){
        return (typeof state.dash_detailed.data != 'undefined')
        
        }
    },
    actions:{
        async populateDetailed({commit,state},payload = {}){
            
                
                let req = await axios.get("api/dadosCustosDetalhados", {
                  params: {
                    pagina: state.pageDetailed,
                    filiais: payload.filiais,
                    safras: payload.safras,
                    variedades: payload.variedades,
                    latadas: payload.latadas,
                    valvulas: payload.valvulas,
                    culturas: payload.culturas,
                    ano: payload.ano,
                    mes:payload.mes
                  },
                });
          
                commit("setDashDetailed",req.data);
                commit("setDashDetailedFiltros",req.data.filtros)
          
               
              
        },

        
        popDetailed({commit},payload){
            commit("setDashDetailed",payload)
        }
    },
    mutations:{
        changePageDetailed(state,payload){
            state.pageDetailed = payload
        },
        setPageDetailed(state,payload){
            state.pageDetailed = payload
        },
        setDashDetailed(state,payload){
            state.dash_detailed = payload
        },
        setDashDetailedFiltros(state,payload){
            state.filtros_detailed = payload
        }
    }
}

export default storeDashBoard;