<template>
  <div>
    <v-list dense color="white">
      <v-list-group v-for="i of items" :key="i.id" v-if="i.hasOwnProperty('children') && i.children.length" v-model="i.active">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon small>{{ i.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="font-size: 12px">{{
            i.menu_name.toUpperCase()
          }}</v-list-item-title>
        </template>
        <CategoryTree :items="i.children" v-if="i.children" />
      </v-list-group>
      <v-list-item :to="i.url" link v-else>
        <v-list-item-icon>
          <v-icon small>{{ i.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title style="font-size: 12px">{{
          i.menu_name.toUpperCase()
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "CategoryTree",
  props: {
    items: Array,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
