import Vue from "vue";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import Stock from "highcharts/modules/stock";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsVue from "highcharts-vue";

HighchartsMore(Highcharts);
Stock(Highcharts);
NoDataToDisplay(Highcharts);
SolidGauge(Highcharts);

Vue.use(HighchartsVue);
