import Vue from "vue";
import VueRouter from 'vue-router'

import store from "../store";

Vue.use(VueRouter);
const { isNavigationFailure, NavigationFailureType } = VueRouter;
const Login = () => import("../components/LoginPage");
const Dashboard = () => import("../components/DashboardPage");
const Importacao = () => import("../components/ImportacaoPage.vue");
const ImportacaoApontamento = () =>
  import("../components/ImportacaoApontamento.vue");
const Programacao = () => import("../components/ProgramacaoPage.vue");
const ContasContabeis = () => import("../components/ContasContabeis");
const Parametrizacao = () => import("../components/Parametrizacao");
const Cadastro = () => import("../components/Cadastro");
const Safras = () => import("../components/Safras");
const CadPerfil = () => import("../components/CadPerfil");
const CadMenu = () => import("../components/CadMenu.vue");
const UserProfile = () => import("../components/UserProfile");
const ListMenus = () => import("../components/ListMenus.vue");
const Processar = () => import("../components/Processar");
const ValorKg = () => import("../components/ValorKg.vue");
const FichaEstoque = () => import("../components/FichaEstoque.vue");
const GerarTxt = () => import("../components/GerarTxt.vue");
const Listagem = () => import("../components/Listagem");
const ListPerfil = () => import("../components/ListPerfil.vue");
const ListUsuarios = () => import("../components/ListUsuarios.vue");
const Report = () => import("../components/Report.vue");
const ReportDespesa = () => import("../components/reports/Despesa.vue");
const ReportEstoqueFormacao = () => import("../components/reports/ReportEstoqueFormacao");
const ForgotPassword = () => import("../components/ForgotPassword")
const ReportResultadoContabil = () =>
  import("../components/reports/ResultadoContabil_old.vue");

const ReportConflitoCusto = () => import("../components/reports/ReportConflito.vue");
const Panorama = () => import("../components/Panorama.vue");

const ResultadoContabil = () => import("../components/reports/ResultadoContabil.vue");
const FichaEntrada = () => import("../components/reports/FichaEntrada.vue");


const Routes = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: ForgotPassword,
    meta: {
        RequiredAuth: false
    }
},
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      RequiredAuth: true,
    },
  },
  {
    path: "/ficha_estoque",
    name: "Ficha estoque",
    component: FichaEstoque,
  },
  {
    path: "/gerar_txts",
    component: GerarTxt,
  },
  {
    path: "/importacao",
    name: "importacao",
    component: Importacao,
    meta: {
      RequiredAuth: true,
    },
  },
  {
    path: "/importacao_apontamento",
    name: "importacao_apontamento",
    component: ImportacaoApontamento,
    meta: {
      RequiredAuth: true,
    },
  },
  {
    path: "/parametrizacao",
    component: Parametrizacao,
    children: [
      {
        path: "contas_contabeis",
        component: ContasContabeis,
      },
      {
        path: "valor_kg",
        name: "Valor KG",
        component: ValorKg,
      },
    ],
  },

  {
    path: "/cadastro",
    component: Cadastro,
    children: [
      // {
      //   path: "users",
      //   name: "user",
      //   component: UserProfile,
      // },
      {
        path: "safras",
        name: "safras",
        component: Safras,
      },
      {
        path: "menus",
        name: "menus",
        component: CadMenu,
      },
      {
        path: "perfil",
        name: "perfil",
        component: CadPerfil,
      },
    ],
  },
  {
    path: "/listagem",
    component: Listagem,
    children: [
      {
        path: "perfil",
        name: "listagem_perfil",
        component: ListPerfil,
      },
      {
        path: "usuarios",

        component: ListUsuarios,
      },
      {
        path: "menus",
        component: ListMenus,
      },
      {
        path: "/listagem/perfil/edit/:id",
        component: CadPerfil,
      },
      {
        path: "/listagem/users/edit/:id",
        component: UserProfile,
      },
      {
        path: "/listagem/menus/edit/:id",
        component: CadMenu,
      },
    ],
  },
  {
    path: "/report",
    component: Report,
    children: [
      {
        path:"saldo_estoque_formacao",
        name:"saldo_estoque_formacao",
        component:ReportEstoqueFormacao
      },
      {
        path: "despesa",
        name: "despesa",
        component: ReportDespesa,
      },
      {
        path: "resultado_contabil",
        name: "resultado_contabil",
        component: ResultadoContabil,
      },
      {
        path:"conflito_ficha_custo",
        name:"conflito_ficha_custo",
        component:ReportConflitoCusto
      },
      {
        path:"ficha_entrada",
        name:"ficha_entrada",
        component:FichaEntrada,
        meta: {
          RequiredAuth: true,
        },
      }
     
    ],
  },
  
  {
    path:"/panorama",
    name:"panorama",
    component:Panorama
  },
  {
    path: "/programacao",
    component: Programacao,
    meta: {
      RequiredAuth: true,
    },
  },
  {
    path: "/processar",
    component: Processar,
  },

  {
    name: "login",
    path: "/login",
    component: Login,
    meta: {
      title: `Login`,
    },
  },

  {
    path: "*",
    redirect: { name: "dashboard" },
  },
];

var router = new VueRouter({
  routes: Routes,
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
    if (
      error.message.includes("Avoided redundant navigation to current location")
    ) {
      return;
    } else {
      console.log(error);
    }
  });
};

router.beforeEach((to, from, next) => {
  const unauthRoutes = ['login', 'register', 'forgot_password']

  if ((unauthRoutes.includes(to.name)) && store.state.authenticated) {

      router.push('dashboard')
      next()
  } else if ((to.meta.RequiredAuth) && !store.state.authenticated) {

      router.push('login')
      next()
  } else if (to.meta.RequiredAuth && to.meta.RequiredRoles && store.state.authenticated) {
      console.log("congress")
      let role = _.first(store.getters.userRole).name
      if (to.meta.RequiredRoles.includes(role)) {
          next();
      } else {
          router.push('dashboard')
      }

  } else {
      next()

  }
});

export default router;
