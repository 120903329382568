

const defaultState = () => {
    return {
        authenticated: false,
        sidebar:false,
        user:{},
        spinner:false,
        programacao_imported:[],
        drawer:false,
        latadas_invalidas:[],
        latadas_duplicadas:[],
        filiais:[],
        isLoading:false,
        anos: [
            {
              valor: 2021,
              text: "2021",
            },
            {
              valor: 2022,
              text: "2022",
            },
            {
              valor: 2023,
              text: "2023",
            },
          ],
          months: [
            {
              valor: 1,
              nome: "Janeiro",
            },
            {
              valor: 2,
              nome: "Fevereiro",
            },
            {
              valor: 3,
              nome: "Março",
            },
            {
              valor: 4,
              nome: "Abril",
            },
            {
              valor: 5,
              nome: "Maio",
            },
            {
              valor: 6,
              nome: "Junho",
            },
            {
              valor: 7,
              nome: "Julho",
            },
            {
              valor: 8,
              nome: "Agosto",
            },
            {
              valor: 9,
              nome: "Setembro",
            },
            {
              valor: 10,
              nome: "Outubro",
            },
            {
              valor: 11,
              nome: "Novembro",
            },
            {
              valor: 12,
              nome: "Dezembro",
            },
          ],
          ciclos:[
            {
              valor:"Produção",
              nome:"Produção"
            },{
              valor:"Formação",
              nome:"Formação"
            }
          ]
    }
 
 
  };

export default defaultState;
