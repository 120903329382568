<template>
  <v-app>
    <v-main>
      <v-container fluid class="d-flex p-0 backContainer" style="padding:0">
        <div class="labelheader" v-if="authenticated"></div>
        <v-navigation-drawer v-model="drawer" class="navigation" :mini-variant="mini" temporary absolute>
          <v-list class="pa-1">
            <div class="d-flex justify-end">
              <v-btn icon @click.stop="mini = !mini" v-if="!mini"><v-icon>mdi-menu-left</v-icon></v-btn>
              <v-btn v-else icon @click.stop="mini = !mini"><v-icon>mdi-menu-right</v-icon></v-btn>
            </div>
          </v-list>
          <v-list>

            <div class="d-flex" style="background-color: white">
              <lottie-animation path="lotties/lottiebussiness.json" :width="92" :height="92" class="mb-2" />

            </div>
          </v-list>


          <CategoryTree :items="this.$store.state.user.menus"></CategoryTree>
          <v-divider></v-divider>


        </v-navigation-drawer>

        <div v-bind:class="{

mainContent: authenticated,
}
" style="width: 100%">
<div style="justify-content: space-between;display: flex;height: 100px;align-items: center;"
  v-if="authenticated">

  <div class="d-flex align-center">
    <v-icon @click.stop="drawer = !drawer" ref="iconMenu">mdi-menu</v-icon>
    <v-btn href="/#/dashboard" color="white" icon>
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <span> </span>
  </div>


  <div class="d-flex justify-end">
    <div class="d-flex align-center">

      <v-menu bottom min-width="200px" rounded offset-y max-height="500px">
        
        <v-card style="background-color: #ffffff;">
          <v-list-item-content>


            <ul class="pa-2">
              <div v-for="(notification, index) in notifications" :key="index" class="pa-2">
                <v-alert border="right" colored-border type="error" style="background-color: F2F1FA;">
                  <div class="d-flex flex-column align-start">
                    <div class="d-flex justify-center flex-column">
                      <span class="text-h6">{{ notification.data?.title }}</span>
                      <div>
                        <span class="text-subtitle-2 text--secondary">{{ notification.data?.msg }}</span>
                        <div> <span class="text-subtitle-2 text--secondary">{{ notification.created_at |
                          moment("D/MM/YYYY HH:mm:ss") }}</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </v-alert>

              </div>
              <v-divider></v-divider>
              <div class="text-center"><span class="text-subtitle-2 text--secondary text-center">Ultimas 5
                  notificações</span></div>

            </ul>

          </v-list-item-content>
        </v-card>
      </v-menu>

      <div class="avatarProfile">

        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="indigo" size="48">
                <span class="white--text text-h5">{{ initials }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="indigo">
                  <span class="white--text text-h5">{{
                    initials
                  }}</span>
                </v-avatar>

                <p class="text-caption mt-1">{{ user.username }}</p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text :to="{ name: 'perfilGeral' }"> Perfil </v-btn>
                <v-divider class="my-3"></v-divider>
                <div>

                  <div class="d-flex justify-center px-2">
                    <v-btn v-on:click="logout" icon><v-icon color="purple">mdi-logout</v-icon></v-btn>
                  </div>

                </div>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>

      </div>
    </div>




  </div>



</div>
<div v-bind:class="{ 'pa-2': authenticated }" style="background-color: white; border-radius: 0.5rem">
  <router-view></router-view>
</div>
<loading :active.sync="isLoading" :can-cancel="true"><loading-component></loading-component></loading>

</div>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
/* @import "../assets/css/styles/sidebar.css"; */
@import "../assets/css/styles/css-loader.css";
@import "../assets/css/styles/sidebar_new.css";

:root {
  --background-dashboard: rgb(34, 39, 54);
}

span {
  font-family: "Montserrat", sans-serif;
}

.container {
  background-color: white;
  height: 100%;
}

.copyright {
  display: flex;
  flex-grow: 1;
  border: solid;
}

.customInput {
  font-size: 16px;
  font-size: max(16px, 1em);
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: #fff;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  background-color: lightblue;
  margin-top: 15px;
}

.inputTable {
  background-color: lightblue;
}

.tableProdutos {
  height: 500px;
  overflow: scroll;
}

.tableProdutos thead th {
  position: sticky;
  top: 0;

  background: #eee;
}

.tableProdutosSaved tbody tr {
  background-color: rgb(33, 33, 87);
  color: white;
}

.checkExcption {
  transform: scale(2);
  margin-top: 10px;
}

.swal2-popup {
  font-family: "Roboto Flex" !important;
}

.content {
  padding: 20px;
  display: flex;
}

.topBar {
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%); */


  display: flex;
  border: solid green;
  justify-content: center;
  align-items: center;
  align-content: center;




}

.avatarProfile,
.bagNotification {
  padding: 10px;
  width: 8%;

}

.parentGeneral {
  display: flex;
  width: 100%;
}

.sideHeader {
  height: 20%;
  width: 100%;
  display: flex;
}

.logoEmpresa {
  display: flex;

  flex-grow: 1;

  flex-direction: column;
  align-items: center;
}

.imgLogo {
  width: 50%;
  border-radius: 0.6rem;
  height: 100%;
}

.menuSide {
  height: 60%;
  overflow: auto;
  padding: 3px;
  margin-top: 10px;
}

.fluidContent {
  border: solid yellow !important;
}

.fixedContent {
  position: fixed !important;
}

.leftSideMobile {
  border-radius: 0.75rem;

  padding: 1rem 1rem;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  font-size: 1rem;
  width: 35%;
  max-width: 17.625rem !important;

  height: 95vh;
  background-color: #fdfdfd;
  /* background-image: linear-gradient(195deg, #42424a, #191919); */
}

.leftSide {
  border-radius: 0.75rem;
  padding: 1rem 1rem;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  border: solid blue;
  width: 19%;
  background-color: #fdfdfd;
  /* max-width: 20.625rem !important; */

  height: 94.5vh;
  /* background-image: linear-gradient(195deg, #42424a, #191919); */
}

.sideBottom {
  height: 20%;
}

.mainContent {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.navigation {
  z-index: 6;

}

.labelheader {
  display: flex;
  width: 100%;
  padding: 0px;
  z-index: 2;
  position: absolute;
  background-color: #5037dd !important;
  min-height: 40%;
}

.backContainer {
  background-color: lightgray;
}

.notification_item {
  background-color: F2F1FA;
}
</style>
<script>

import { directive as onClickaway } from "vue-clickaway";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import CategoryTree from "./frames/CategoryTree.vue";
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import LoadingComponent from "./components/frames/LoadingComponent.vue";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  watch: {},
  components: {
    LottieAnimation,
    CategoryTree,
    LoadingComponent,
    Loading,
  },
  data() {
    return {

      mini: false,
      drawer: null,
      showSideBar: null,
      list: "",
      toggleSidebar: true,
      toastOption: {
        position: "top",
      },
      menus: this.$store.state.user.menus,
      usuario: this.$store.state.user.name,

    };
  },

  methods: {
    ...mapMutations(["setDrawer", "setNotifications", "setCurrImg", "setViewDoc"]),
    ...mapActions(["hideLoader"]),

    handleModal(status) {
      this.setViewDoc(false)
      this.setCurrImg("")

    },
    collapse(value) {
      console.log(value)
      this.$store.state.user.menus.map((item) => {
        item.active = false;
      });
    },
    onToggleCollapse() { },
    onItemClick() { },
    logout() {
      this.axios.post("api/logout").then((response) => {
        this.$store.commit("SET_LOGOUT");
      });
    },



  },

  computed: {
    ...mapState(["authenticated", "user", "isLoading", "notifications", "viewDoc", "curImg"]),
    
    initials() {

      if (this.user.nome) {
        let nome = this.user.nome.split(" ");
        return nome[0].charAt(0) + nome[1].charAt(0);
      }


    },
    largeDevice() {
      return _.includes(["lg", "xl"], this.$vuetify.breakpoint.name);
    },
    // drawer: {

    //   get() {

    //     return !this.$store.state.drawer;
    //   },
    //   set(value) {

    //     this.setDrawer(value);
    //   },
    // },
  },
  mounted() {


    this.hideLoader();
  },




};
</script>